$(() => {
  $('.bxslider').bxSlider({
    minSlides: 2,
    maxSlides: 8,
    responsive: true,
    slideWidth: 300,
    tickerHover: true,
    ticker: true,
    speed: 50000
  });
});

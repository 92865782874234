$(() => {
  function showGenerateReportModal() {

    var source = $('[data-template="generate-report-modal"]').html();
    var modalTpl = Handlebars.compile(source);
    var modalWindow = modal.window(modalTpl());

    modalWindow.onDone(() => {
      var form = $('[data-form="generate-report"]');

      if (isFormValid()) {
        // Get selected story ids
        var storyIds = new Array()
        $('figure.card.influencer.selected').each(function() {
          storyIds.push($(this).data('influencer-card'));
        });

        $('[data-input="report-stories"]').val(storyIds);

        form.submit();
      }
      else {
        showInvalidFormModal();
        return
      }
    });

    modalWindow.show()

    function showInvalidFormModal() {
      var message = ""
      if (!hasTitle()) {
        message = 'Please give the report a title.'
      }
      else if (!hasStartDate() || !hasEndDate) {
        message = 'Please specify a start and end date for the report.'
      }
      else if (!isCurrencySelected()) {
        message = 'Please specify a currency for the report.'
      }

      modal.
        alert(message, 'Warning').
        onDone(showGenerateReportModal).
        show();
    }

    function hasTitle() {
      if ($('[data-input="report-title"]').length == 0) return false;
      return $('[data-input="report-title"]').val().length !== 0
    }

    function hasStartDate() {
      if ($('[data-input="start-date"]').length == 0) return false;
      return $('[data-input="start-date"]').val().length !== 0
    }

    function hasEndDate() {
      if ($('[data-input="end-date"]').length == 0) return false;
      return $('[data-input="end-date"]').val().length !== 0
    }

    function isCurrencySelected() {
      return $('[data-input="report-currency"]').val().length !== 0
    }

    function isFormValid() {
      if (hasTitle() && hasStartDate() && hasEndDate() && isCurrencySelected()) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  $('*[data-target="modal-generate-report"]').click(showGenerateReportModal);
});

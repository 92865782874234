$(() => {
  $('[data-action="select-stories"]').on('click', event => {
    var id = $(event.target).data('id')

    if (!$('*[data-influencer-card="' + id + '"]').hasClass('selected')) {
      $('*[data-influencer-card="' + id + '"]').addClass('selected');
      $('*[data-navbar="influencer-selector"]').removeClass('empty');

      var count = parseInt($('#selected-stories-count').text()) + 1;
      $('#selected-stories-count').text(count);
    }
    else {
      $('*[data-influencer-card="' + id + '"]').removeClass('selected');
      var count = parseInt($('#selected-stories-count').text()) - 1;
      $('#selected-stories-count').text(count);

      if (count == 0) {
        $('*[data-navbar="influencer-selector"]').addClass('empty');
      }
    }

    $('#selected-stories-count').text(count);
    if (count == 1) {
      $('#story-text').text("story");
    }
    else {
      $('#story-text').text("stories");
    }
  });
});

 $(() => {
   if ($('[data-account-type="instagram"]').data('account-status') == 'not-selected') {
     if ($('.alert.alert-error').length == 0 ) {
       showSelectChannel();
     }
   }
 
    $('[data-action="select-instagram-account"]').on('click', showSelectChannel);
 
    function showSelectChannel(event) {
     var source = $('[data-template="select-instagram-account-modal"]').html();
     var tpl = Handlebars.compile(source);
     var modalWindow = modal.window(tpl());
 
      modalWindow.onDone(() => {
       if (isProfileSelected()) {
         var form = $('[data-form="select-instagram-account-form"]');
         form.submit();
       }
       else {
         modal.
           alert(
             'Please select a channel.',
             'Warning'
           ).
           show();
       }
     });
 
      // Reset the select box
     modalWindow.onCancel(() => {
       $('[data-input="page-id"] option').remove()
     });
 
      modalWindow.show()
   }
 
    function isProfileSelected() {
     return $('[data-input="page-id"]').val().length !== 0
   }
 });

var modal = (function($, Handlebars) {
  /**
   * Alert Modal
   */
  function createAlert(message, title='Alert') {
    var tpl  = Handlebars.compile(template.alertModal);
    var view = tpl({message: message, title: title});

    return createWindow(view);
  }

  /**
   * Confirm Modal
   */
  function createConfirm(message, title='Confirm') {
    var tpl  = Handlebars.compile(template.confirmModal);
    var view = tpl({message: message, title: title});

    return createWindow(view);
  }

  /**
   * Prompt Modal
   */
  function createPrompt(message, title='Prompt', placeholder='') {
     var tpl  = Handlebars.compile(template.promptModal);
     var view = tpl({message: message, title: title, placeholder: placeholder});

     var window = createWindow(view);

     var oldOnDone = window.onDone;
     window.onDone = (callback) => {
      return oldOnDone((modal) => {
        callback(
          modal.find('[data-field="value"]').val(),
          modal
        );
      });
     };

     return window;
  }

  /**
   * Share Modal
   */
  function createShare(message, title='Share', copyText) {
    var tpl  = Handlebars.compile(template.shareModal);
    var view = tpl({message: message, title: title, copyText: copyText});

    return createWindow(view);
  }

  /**
   * General Modal
   */
  function createWindow(view) {
    return window(view);
  }

  function window(view) {
    var viewDom   = $(view); 
    var isVisible = false;

    var showCallback   = ()=>{};
    var hideCallback   = ()=>{};
    var doneCallback   = ()=>{};
    var cancelCallback = ()=>{};

    function showWindow() {
      if (isVisible) return;

      viewDom.find('[data-action="done"]').click(onDoneClick);
      viewDom.find('[data-action="cancel"]').click(onCancelClick);

      $('body').append(viewDom);
      isVisible = true;
      showCallback(viewDom);
    }

    function hideWindow() {
      if (!isVisible) return;

      viewDom.remove();
      isVisible = false;
      hideCallback(viewDom);
    }

    function onDoneClick() {
      doneCallback(viewDom);
      hideWindow(viewDom);
    }

    function onCancelClick() {
      cancelCallback(viewDom);
      hideWindow(viewDom);
    }

    var that = {
      show: showWindow,
      hide: hideWindow,

      onShow:   (callback)=>{showCallback   = callback; return that;},
      onHide:   (callback)=>{hideCallback   = callback; return that;},
      onDone:   (callback)=>{doneCallback   = callback; return that;},
      onCancel: (callback)=>{cancelCallback = callback; return that;}
    };

    return that;
  }

  return {
    alert:   createAlert,
    confirm: createConfirm,
    prompt:  createPrompt,
    share:   createShare,
    window:  createWindow
  };
})(jQuery, Handlebars);

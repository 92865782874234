var template = {};

template.genericModal = `
  <div class="modal2">
    <div class="modal2-backdrop"></div>
    <div class="modal2-container">
      <div class="modal2-header">
        <div class="modal2-header-title">
          {{{header}}}
        </div>
        <div class="modal2-header-close">
          <button class="icon-button icon-cross" data-action="cancel"></button>
        </div>
      </div>
      <div class="modal2-body">
        {{{body}}}
      </div>
      <div class="modal2-footer text-right">
        {{{footer}}}
      </div>
    </div>
  </div>
`;

template.confirmModal = Handlebars.compile(template.genericModal)({
  header: `{{title}}`,
  body: `{{message}}`,
  footer: `
      <button data-action="done" class="button">OK</button>
      <button data-action="cancel" class="button transparent">Cancel</button>
    `
});

template.alertModal = Handlebars.compile(template.genericModal)({
  header: `
    <img src="/assets/imgs/danger.svg" class="middle" width="40" height="40">
    {{title}}
  `,
  body: `{{message}}`,
  footer: `
      <button data-action="done" class="button">OK</button>
    `
});

template.promptModal = Handlebars.compile(template.genericModal)({
  header: `{{title}}`,
  body: `
    <div class="input-box">
      <input class="input-box-field" data-field="value" placeholder="{{placeholder}}">
      <label class="input-box-label">{{message}}</label>
    </div>
  `,
  footer: `
      <button data-action="done" class="button">OK</button>
      <button data-action="cancel" class="button transparent">Cancel</button>
    `
});

template.shareModal = Handlebars.compile(template.genericModal)({
  header: `{{title}}`,
  body: `
    <div class="row">
      <div class="col s10">
        <div class="input-box">
          <input class="input-box-field" value="{{copyText}}" readonly>
          <label class="input-box-label">{{message}}</label>
        </div>
      </div>
      <div class="col s2">
        <button
          class="icon-button icon-button-copy icon-copy tooltip-container tooltip-right"
          data-clipboard-text="{{copyText}}"
          data-toggle="tooltip"
          data-tooltip-direction="left"
          data-tooltip-text="Copy">
        </button>
      </div>
    </div>
  `,
  footer: `
    <button data-action="done" class="button">Done</button>
  `
});
